<template>
    <v-menu transition="slide-y-transition" v-model="showmenu" rounded :close-on-content-click="false" app clipped offset-y left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" @click="showmenu = true">
                <v-badge id="badge" small :content="messages" :value="messages" color="darkgreen" overlap>
                    <v-icon v-bind="attrs" v-on="on" class="">mdi-bell</v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list id="notificationlist" v-if="notifications.length">
            <!-- @click="clickNotification(notification)" -->
            <v-list-item v-for="(notification, index) in notifications" :key="index" class="notification ml-auto" @click="clickNotification(notification)">
                <v-list-item-title>
                    <v-row>
                        <div class="mx-3 py-auto my-auto">
                            <v-avatar color="#f7f7f7">
                                <v-icon :color="notification.icon_color"> {{ notification.icon }}</v-icon></v-avatar
                            >
                        </div>
                        <v-col class="py-auto my-auto nofitificationtext">
                            <v-row
                                ><span class="listname"> {{ notification.listname }}</span></v-row
                            >
                            <v-row class="message">
                                {{ notification.message }}
                            </v-row>
                            <v-row class="message" v-if="notification.message_2">
                                {{ notification.message_2 }}
                            </v-row>
                            <v-row class="date">
                                {{ notification.date }}
                            </v-row>
                        </v-col>
                        <div class="mx-3 py-auto my-auto">
                            <v-btn v-if="notification.type !== '1'" icon class="ml-auto" @click="deleteNotification(notification.id)">
                                <v-icon color="primary">mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </v-row>
                    <v-progress-linear :class="notifications.length > 1 ? 'mb-2' : ''" v-if="notification.type == '1'" v-model="notification.progress" query rounded></v-progress-linear>
                    <hr id="notificationline" class="mr-2" v-if="notifications.length > 1 && index != notifications.length - 1" />
                </v-list-item-title>
            </v-list-item>
        </v-list>

        <!-- no notifications -->
        <v-list id="notificationlist" v-else>
            <v-list-item>
                <v-list-item-title>
                    <v-row>
                        <v-col class="py-auto my-auto">
                            <v-icon :color="noNotifications.icon_color"> {{ noNotifications.icon }}</v-icon>
                        </v-col>
                        <v-col class="py-auto my-auto nofitificationtext">
                            {{ noNotifications.message }}
                        </v-col>
                    </v-row>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import sound from "../assets/audio/notification-sound.mp3"
    export default {
        name: "Notifications",
        props: {},
        components: {},
        data: () => ({
            messages: 0,
            showmenu: false,
            notifications: [
                // {
                //     updateinfo: true,
                //     listname: "January Update",
                //     message: "New features and design optimizations have been released. ",
                //     message_2: "Take a look at the update information to find all changes.",
                //     date: "20.01.2023",
                //     icon: "mdi-bell",
                //     icon_color: "darkgreen"
                // }
            ],
            noNotifications: {
                message: "No notifications here for you!",
                icon: "mdi-bell-off",
                icon_color: "darkgreen"
            }
        }),
        methods: {
            playNotificationSound() {
                const audio = new Audio(sound)
                audio.play()
            },
            createNotification(data) {
                let notificationObject
                if (data.type == "0") {
                    //type 0 = error
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-alert-circle",
                        icon_color: "red",
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications.splice(index, 1)
                            this.messages--
                        }
                    }
                } else if (data.type == "1") {
                    //type 1 = branddashboard is creating
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-clock",
                        icon_color: "orange",
                        progress: 0,
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                } else if (data.type == "2") {
                    //type 1 = branddashboard created
                    // remove id and push new notification
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications.splice(index, 1)
                            this.messages--
                        }
                    }
                    notificationObject = {
                        type: data.type,
                        message: data.message,
                        icon: "mdi-comment-check",
                        icon_color: "green",
                        listname: data.listname,
                        date: this.getDateTime(),
                        id: data.id
                    }
                } else if (data.type == "3") {
                    //type 3 = progress update
                    console.log(this.notifications)
                    for (let index = 0; index < this.notifications.length; index++) {
                        if (this.notifications[index].id == data.id) {
                            this.notifications[index].progress = data.progress
                            console.log(this.notifications)
                        }
                    }
                    return null
                    // notificationObject = {
                    //   message: data.message,
                    //   icon: 'mdi-comment-check',
                    //   icon_color: 'green',
                    //   listname: data.listname,
                    //   date: this.getDateTime(),
                    //   id: Math.floor(Math.random() * 999)
                    // }
                }
                return notificationObject
            },
            getDateTime() {
                let dateTime = new Date()
                let month = dateTime.getMonth() + 1
                let day = dateTime.getDate()
                let hours = dateTime.getHours()
                let minutes = dateTime.getMinutes()
                return day + "." + month + " | " + hours + ":" + minutes
            },
            clickNotification(notification) {
                if (notification.type == "2") {
                    this.$router.push("/brand-index/" + notification.listname)
                }
                if (notification.updateinfo) {
                    this.$emit("showUpdateInfo")
                }
            },
            deleteNotification(id) {
                for (let index = 0; index < this.notifications.length; index++) {
                    if (this.notifications[index].id == id) {
                        this.notifications.splice(index, 1)
                        this.messages--
                    }
                }
                this.showmenu = false
            }
        },
        mounted() {
            this.$socket.on("getNotification", (data) => {
                //console.log(data.message)

                let notificationObject = this.createNotification(data)
                if (notificationObject) {
                    this.notifications.unshift(notificationObject)
                    this.showmenu = false
                    this.messages++
                    this.showmenu = true
                    this.playNotificationSound()
                }
            })
            // this.$socket.on("showReloadmessage", (data) => {
            //     console.log("Refreshing frontend initiated...showing message")
            //     this.showUpdateMessage = true
            //     setInterval(() => {
            //         if (this.timer > 0) {
            //             this.timer--
            //         }
            //     }, 1000)
            // })
            // this.$socket.on("reloadFrontend", (data) => {
            //     console.log("Refreshing frontend...")
            //     this.$router.go()
            // })
        }
    }
</script>
<style scoped>
    .notification:hover {
        background-color: #e9e9e9;
    }
    .nofitificationtext {
        font-family: Nunito;
    }
    #notificationline {
        margin-left: 60px !important;
        border-radius: 5px;
        border: 1px solid rgb(228, 228, 228);
    }
    .message {
        font-size: 0.9em;
    }
    .date {
        padding-top: 2px;
        font-size: 0.6em;
    }
    .listname {
        font-weight: bold;
        font-size: 0.9em;
    }
</style>
